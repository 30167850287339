<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">

          <ul class="nav nav-admin">
            <li>
              <router-link to="/admin/dashboard">
                <i class="material-icons">dashboard</i>
                <span>Dashboard</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/reservations">
                <i class="material-icons">today</i>
                <span>Reservierungen</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/vehicles">
                <i class="material-icons">drive_eta</i>
                <span>Fahrzeuge</span>
              </router-link>
            </li>
            <li class="active">
              <router-link to="/admin/locations">
                <i class="material-icons">gps_fixed</i>
                <span>Standorte</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/customers">
                <i class="material-icons">group</i>
                <span>Kunden</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/settings">
                <i class="material-icons">settings</i>
                <span>Einstellungen</span>
              </router-link>
            </li>
          </ul>

        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/locations">Standorte</router-link></li>
              <li>Standort hinzufügen</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/locations" class="button button-grey button-small">Alle Standorte ›</router-link></p>
            </div>
          </header>

          <form @submit="create_location" class="form" method="post" style="margin-top:20px">

            <div class="form-wrap">
              <label for="name">Name</label>
              <input v-model="location.name" class="form-input" type="text" name="name" id="name">
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="form-wrap">
                  <label for="contact_email">Kontakt E-Mail</label>
                  <input v-model="location.contact_email" class="form-input" type="text" name="contact_email" id="contact_email">
                </div>
              </div>
              <div class="col-12">
                <div class="form-wrap">
                  <label for="contact_phone">Kontakt Telefon</label>
                  <input v-model="location.contact_phone" class="form-input" type="text" name="contact_phone" id="contact_phone">
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-18">
                <div class="form-wrap">
                  <label for="street_name">Straße</label>
                  <input v-model="location.street_name" class="form-input" type="text" name="street_name" id="street_name">
                </div>
              </div>
              <div class="col-6">
                <div class="form-wrap">
                  <label for="street_number">Hausnummer</label>
                  <input v-model="location.street_number" class="form-input" type="text" name="street_number" id="street_number">
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-6">
                <div class="form-wrap">
                  <label for="zip_code">Postleitzahl</label>
                  <input v-model="location.zip_code" class="form-input" type="text" name="zip_code" id="zip_code">
                </div>
              </div>
              <div class="col-18">
                <div class="form-wrap">
                  <label for="city">Stadt</label>
                  <input v-model="location.city" class="form-input" type="text" name="city" id="city">
                </div>
              </div>
            </div>

            <div class="form-wrap">
              <label for="description">Beschreibung</label>
              <textarea v-model="location.description" rows="5" class="form-input" type="text" name="description" id="description"></textarea>
            </div>

            <div class="form-wrap">

              <input type="checkbox" id="checkbox" v-model="location.enabled">
              <label for="checkbox">Aktiviert?</label>

            </div>

            {{ errors }}

            <p>
              <input type="submit" value="Standort erstellen ›" class="button button-orange button-small">
            </p>

          </form>


        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'admin_reservations',
  data () {
    return {
      errors: [],
      location: {
        name: "",
        contact_email: "",
        contact_phone: "",
        street_name: "",
        street_number: "",
        zip_code: "",
        city: "",
        enabled: false
      }
    }
  },
  methods: {
    get_locations() {},
    create_location: function (e) {
      e.preventDefault();

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/locations', this.location, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$router.push('/admin/locations');
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      })

    }
  },
  mounted () {}
}
</script>

<style lang="scss">

</style>
